import React, { useEffect } from 'react';
import './Partnership.scss';
import OverviewContainer from './Components/OverviewContainer';
import FeatureCard from './Components/FeatureCard';
import IncomeContainer from './Components/IncomeContainer';
import PartnershipContainer from './Components/PrtnershipContainer';
import Layout from '../../Components/Layout/Layout.js';
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';

export default function Partnership() {

    const navigate = useNavigate();
    const FeaturesData = [
        { title: 'Trusted', tagline: 'Trusted By Participants In Over 150+ Countries', description: 'The deposit system in Pioneer Capital is extremely fast, wherein traders will use any crypto wallet.', bg: 'Assets/Images/globe-bg.svg', x: 'right', y: 'bottom' },
        { title: 'Payments', tagline: 'Promotional Tools & Material', description: 'The deposit system in Pioneer Capital is extremely fast, wherein traders will use any crypto wallet.', bg: 'Assets/Images/payments-bg.svg', x: 'center', y: '85%' },
        { title: 'Analytics', tagline: 'Detailed, Realtime Statistics', description: 'The deposit system in Pioneer Capital is extremely fast, wherein traders will use any crypto wallet.', bg: 'Assets/Images/analytics-bg.svg', x: 'center', y: '85%' },
        { title: 'Withdrawal', tagline: 'Instant Withdrawals', description: 'The deposit system in Pioneer Capital is extremely fast, wherein traders will use any crypto wallet.', bg: 'Assets/Images/withdraw-bg.svg', x: 'center', y: '85%' },
    ];

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <Layout>
            <Helmet>
                <title>Partnership</title>
            </Helmet>
            <div className='partnership-container'>
                <div className='side-padding pt-0'>
                    <div className='partnership-bg'>
                        <div className='pt-5'>
                            <div className='title-blue mb-4 mt-5'>
                                <p className='m-0'>Partnership</p>
                            </div>
                            <p className='main-text mb-4'>Become an Introducing Broker-Boost Your Earnings</p>
                            <p className='m-0 short-description'>Expand our client base and earn generous commissions in the process</p>
                            <div className='mt-5'>
                                <a href='#partnership-form' >
                                    <button className='btn-blue-bg' onClick={() => navigate('/login')}>Become a partner</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='partnership-bottom-bg'></div>

                <div>
                    <OverviewContainer />
                </div>

                <div className='why-us-container py-5'>
                    <div className='side-padding'>
                        <div className='my-0 my-md-5 pt-3 head'>
                            <div className='text-left w-30 mt-5 mt-md-0'>
                                <p className='head-text mb-4'>Join The Winning Team: Discover The Benefits Of partnering And Earning With Pioneer Capital!</p>
                                <a href='#partnership-form' >
                                    <button className='btn-white-bg mt-3 mt-md-5' onClick={() => navigate('/login')}>Get Started</button>
                                </a>
                            </div>
                            <div className='mt-4 mt-md-0'>
                                <div className='title-white mb-4'>
                                    <p className='m-0'>Why us</p>
                                </div>
                                <p className='main-title'>Why partner with Pioneer Capital?</p>
                            </div>
                        </div>
                        <div className='features-container py-5 row px-0 mx-0'>
                            {
                                FeaturesData?.map((feature, index) => (
                                    <FeatureCard key={index} data={feature} index={index} />
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div>
                    <IncomeContainer />
                </div>
                <div>
                    <PartnershipContainer />
                </div>
            </div>
        </Layout>
    )
}
