import React from 'react';

const EmailTemplate = ({ formDetails }) => {
    console.log(formDetails);
  const {
    firstname,
    lastname,
    email,
    country_name,
    city,
    mobile,
    ticket_title,
    description
  } = formDetails;

  return `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Contact Us Form Submission</title>
        <style>
            body {
                font-family: Arial, sans-serif;
                background-color: #f4f4f4;
                padding: 20px;
            }
            .container {
                background-color: #ffffff;
                padding: 20px;
                border-radius: 8px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                max-width: 600px;
                margin: 0 auto;
            }
            h2 {
                color: #333;
            }
            p {
                font-size: 16px;
                color: #555;
            }
            .details {
                background-color: #f9f9f9;
                padding: 10px;
                border-radius: 4px;
                margin-top: 10px;
            }
            .details p {
                margin: 5px 0;
            }
        </style>
    </head>
    <body>
        <div class="container">
            <h2>New Contact Us Form Submission</h2>
            <p>You have received a new inquiry from your website contact form. Below are the details:</p>
            <div class="details">
                <p><strong>First Name:</strong> ${firstname}</p>
                <p><strong>Last Name:</strong> ${lastname}</p>
                <p><strong>Email:</strong> ${email}</p>
                <p><strong>Country:</strong> ${country_name}</p>
                <p><strong>City:</strong> ${city}</p>
                <p><strong>Mobile:</strong> ${mobile}</p>
                <p><strong>Ticket Title:</strong> ${ticket_title}</p>
                <p><strong>Description:</strong> ${description}</p>
            </div>
        </div>
    </body>
    </html>
  `;
};

export default EmailTemplate;