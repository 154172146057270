import React, { useEffect } from 'react';
import './Login.scss';
import Layout from '../../Components/Layout/Layout.js';
import { Helmet } from "react-helmet";

export default function Login() {

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div className='login-container'>
        <div className='side-padding'>
          <div className='row my-5 py-4 flex align-items-center'>
            <div className='col-md-6 col-lg-7'>
              <div>
                <p className='main-text mb-4'>Trade with First Decentralised Forex Brocker</p>
                <p className='m-0 description'>In a never-before-seen decentralized deposit & Withdrawal style, Pioneer Capital offers round-the-clock crypto transfers without any human intervention.</p>
              </div>
            </div>
            <div className='col-md-6 col-lg-5 pl-lg-5 mt-5 mt-md-0'>
              <div className='login-form p-5 ml-lg-5'>
                <p className='main-title'>Welcome Back</p>
                <p className='title'>Sign in to continue.</p>
                <div className='devider px-5 w-75 mx-auto my-4'></div>
                <input type='email' className='w-100' placeholder='Email' />
                <input type='password' className='w-100 mt-4' placeholder='Password' />
                <p className='link mt-2'>Forgot Password</p>
                <button className='btn-blue-bg py-2 w-100 mt-3'>Login</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}