import React, { useEffect } from 'react';
import './Indices.scss';
import Layout from '../../../Components/Layout/Layout.js';
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';


export default function Indices() {

    const navigate = useNavigate();

    const FeaturesData = [
        { icon: 'Assets/Images/how-it-works-2.svg', title: 'Wide Range', description: 'Options' },
        { icon: 'Assets/Images/wide-range.svg', title: '+154 Pair', description: 'of Coins' },
        { icon: 'Assets/Images/instruments.svg', title: 'Instruments', description: 'Features' },
        { icon: 'Assets/Images/how-it-works-4.svg', title: 'Indices', description: 'Forex & crypto markets' }
    ];

    const BenefitsData = [
        { icon: 'Assets/Images/global-market.svg', title: 'Exposure to Global Markets', description: 'Access major global markets, like the Dow Jones, the S&P 500, the FTSE 100 and the Nikkei 225, among others.' },
        { icon: 'Assets/Images/diversification.svg', title: 'Diversification', description: 'Diversify your investment portfolio to gain exposure to a wide range of commodities or other financial assets.' },
        { icon: 'Assets/Images/favourable-swaps.svg', title: 'Leveraged Trading', description: 'Indices trading allows for leveraged trading, meaning you can trade with a larger position than your account balance.' },
        { icon: 'Assets/Images/high-liquidity.svg', title: 'Liquidity', description: 'Indices trading is a highly liquid market, meaning there are plenty of buyers and sellers at any given time.' },
    ];

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const statusIdentifier = (current, previous, digit) => {
        if (current?.toFixed(digit || 3) > previous?.toFixed(digit || 3)) {
            return '#28a745a1';
        } else {
            return '#ff00188c';
        }
    }

    return (
        <Layout>
            <Helmet>
                <title>Indices</title>
            </Helmet>
            <div className='instruments-indices-container'>
                <div className='indices-container py-5'>
                    <div className='side-padding py-5'>
                        <div className='py-4 head'>
                            <div>
                                <div className='title-white mb-4'>
                                    <p className='m-0'>Indices</p>
                                </div>
                                <p className='main-title'>Ride the market waves with Pioneer Capital's indices CFD trading</p>
                            </div>
                            <div className='text-right w-30 mt-5 mt-md-0'>
                                <p className='overview-head-text mb-4'>Trade global markets with high leverage and low costs.</p>
                                <div className='mt-5 pt-md-5 d-flex justify-content-end'>
                                    <button className='btn-white-bg mt-md-5' onClick={() => navigate('/login')}>Open Live Account</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='overview-container py-5'>
                    <div className='side-padding py-5'>
                        <div>
                            <div className='title-blue mb-4'>
                                <p className='m-0'>Overview</p>
                            </div>
                            <p className='main-title'>Trade popular <span>indices</span> and <span>seize</span> your next trading opportunity</p>
                            <p className='description mb-0 mt-4'>Maximize your trading potential with leverage and low spreads on major indices.</p>
                            <button className='mt-5 btn-blue-bg' onClick={() => navigate('/login')}>Start Trading Now</button>
                        </div>
                    </div>
                </div>
                <div className='start-now'>
                    <div className='feature-container-bg-color'>
                        <div className='side-padding py-4'>
                            <div className='feature-container'>
                                {
                                    FeaturesData?.map((feature, index) => (
                                        <div className='feature-card my-3' key={index}>
                                            <div className='d-flex'>
                                                <div>
                                                    <div className='icon-wrapper'>
                                                        <img height={28} className='cursor-pointer' src={feature?.icon} alt='icon' />
                                                    </div>
                                                </div>
                                                <p className='title m-0 ml-3'>{feature?.title}</p>
                                            </div>
                                            <p className='m-0 mt-1 description'>{feature?.description}</p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>

                    <div className='benefits-container py-5'>
                        <div className='side-padding py-md-5'>
                            <div className='head'>
                                <div className='text-left'>
                                    <p className='head-text w-30 mb-4'>Access the best tools and resources throughout the trading.</p>
                                    <div className='mt-5 d-flex'>
                                        <button className='btn-blue-bg' onClick={() => navigate('/login')}>Open Live Account</button>
                                    </div>
                                </div>
                                <div className='mt-5 mt-md-0'>
                                    <div className='title-blue mb-4'>
                                        <p className='m-0'>Benefits</p>
                                    </div>
                                    <p className='main-title'>Tap into profits with popular indices</p>
                                </div>
                            </div>
                            <div className='row m-0 px-0 pt-5'>
                                {
                                    BenefitsData?.map((benefit, index) => (
                                        <div key={index} className={`col-md-6 my-4 ${index % 2 == 0 ? 'pl-md-0 pr-md-4' : 'pr-md-0 pl-md-4'}`}>
                                            <div className='benefit-card py-4 pr-4'>
                                                <div className='icon-wrapper'>
                                                    <img height={32} className='cursor-pointer' src={benefit?.icon} alt='icon' />
                                                </div>
                                                <div className='pl-4'>
                                                    <p className='title my-3'>{benefit?.title}</p>
                                                    <p className='description m-0'>{benefit?.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
