import React, { useEffect } from 'react';
import './ContactUs.scss';
import FaqsContainer from './Components/FaqsContainer';
import ContactForm from './Components/ContactContainer';
import Layout from '../../Components/Layout/Layout.js';
import { Helmet } from "react-helmet";

export default function ContactUs() {

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <Layout>
            <Helmet>
                <title>Support Center</title>
            </Helmet>
            <div className='contact-us-container'>
                <div className='side-padding'>
                    <div className='head mt-5 pt-5'>
                        <div className='title-blue'>
                            <p className='m-0'>Contact</p>
                        </div>
                        <div className='mt-4'>
                            <p className='main-text mb-4'>Let’s talk</p>
                            <p className='m-0 description'>Are you an existing or new user to trade? We are here to answer your questions and resolve issues 24/7.</p>
                        </div>
                    </div>
                    <div className='contact-card-wrapper my-0 py-5 my-md-5'>
                        <div className='contact-card-container pb-3 pt-4 pr-3 my-3'>
                            <div className='icon-wrapper'>
                                <img height={40} className='cursor-pointer' src='Assets/Images/phone-blue.svg' alt='icon' />
                            </div>
                            <div className='contact-info'>
                                <p className='my-3 value'>Call Support</p>
                                <a className='my-2 title' href="tel:+971585802446" target='_blank'>+971 58 580 2446</a>
                            </div>
                        </div>
                        <div className='contact-card-container pb-3 pt-4 pr-3 my-3'>
                            <div className='icon-wrapper'>
                                <img height={40} className='cursor-pointer' src='Assets/Images/mail-blue.svg' alt='icon' />
                            </div>
                            <div className='contact-info'>
                                <p className='my-3 value'>Raise a ticket</p>
                                <a className='my-2 title' href="mailto:admin@pioneercapitalmarkets.com" target='_blank'>admin@pioneercapitalmarkets.com</a>
                            </div>
                        </div>
                        <div className='contact-card-container pb-3 pt-4 pr-3 my-3'>
                            <a href='#faq'>
                                <div className='icon-wrapper'>
                                    <img height={40} className='cursor-pointer' src='Assets/Images/global-blue.svg' alt='icon' />
                                </div>
                                <div className='contact-info'>
                                    <p className='my-3 value'>Read most common questions</p>
                                    <p className='my-2 title'>Read FAQs</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div>
                    <FaqsContainer />
                </div>
                <div>
                    <ContactForm />
                </div>
            </div>
        </Layout>
    )
}
