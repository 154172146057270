import React, { useEffect } from 'react';
import './Blogs.scss';
import Layout from '../../Components/Layout/Layout.js'
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";

export default function Blogs() {

    const navigate = useNavigate();
    const RecentNewsData = [
        { id: '2', img: 'Assets/Images/trading-blog-list.webp', category: 'Trading', date: 'Oct 31, 2023', title: '4 Powerful Reversal Chart Patterns That Every Trader Must Know', description: 'As we venture into the dynamic world of forex trading, grasping the visual language of forex charts becomes an essential skill set.' }
    ];

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <Layout>
            <Helmet>
                <title>Blogs</title>
            </Helmet>
            <div className='blogs-container'>
                <div className='blog-articles py-5'>
                    <div className='side-padding py-md-5'>
                        <div className='title-blue mb-4 '>
                            <p className='m-0'>Blog Articles</p>
                        </div>
                        <p className='main-text pb-5'>Lates news about trading</p>
                        <div className='article-card py-4 pr-4 mt-5'>
                            <div className='row p-0 m-0'>
                                <div className='col-md-6 px-0'>
                                    <div className='title-wrapper d-flex align-items-center'>
                                        <p className='m-0 title'>Trading</p>
                                        <p className='m-0 ml-3 date-text'>Oct 30, 2023</p>
                                    </div>
                                    <div className='pl-4'>
                                        <p className='title my-3'>5 Signs That Your Trading Approach May Be Wrong</p>
                                        <p className='description'>The foreign exchange market holds the unparalleled distinction of being the most liquid market globally, with an astounding daily trading volume.</p>
                                        <button className='btn-read-more mt-4' onClick={() => navigate('/blog?id=1')}>{`Read more >`}</button>
                                    </div>
                                </div>
                                <div className='col-md-6 pr-md-0 text-right mt-4 mt-md-0 d-flex align-items-center'>
                                    <img className='blog-img' src='Assets/Images/trading-blog-img.webp' alt='blog-img' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='recent-news-container py-5'>
                    <div className='side-padding py-md-5'>
                        <p className='title mb-5'>Recent News</p>
                        <div className='row m-0 p-0'>
                            {RecentNewsData?.map((news, index) => (
                                <div className='my-3 col-md-4' key={index}>
                                    <div className='news-card'>
                                        <img src={news?.img} alt='news-img' />
                                        <div className='title-wrapper d-flex align-items-center mt-4'>
                                            <p className='m-0 title'>{news?.category}</p>
                                            <p className='m-0 ml-3 date-text'>{news?.date}</p>
                                        </div>
                                        <div className='p-3'>
                                            <p className='mt-4 title'>{news?.title}</p>
                                            <p className='mt-3 description'>{news?.description}</p>
                                            <button className='btn-read-more mb-2 pl-0' onClick={() => navigate(`/blog?id=${news?.id}`)} >{`Read more >`}</button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
