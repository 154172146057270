export const NavbarData = [
    {
        title: 'Instruments',
        activeUrl: ['/forex', '/metals', '/crypto', '/cfds', '/indices'],
        children: [
            {
                title: 'Forex',
                url: '/forex'
            },
            {
                title: 'Crypto',
                url: '/crypto'
            },
            {
                title: 'Metals',
                url: '/metals'
            },
            {
                title: 'CFDs',
                url: '/cfds'
            },
            {
                title: 'Indices',
                url: '/indices'
            },
        ]
    },
    {
        title: 'Platform',
        activeUrl: ['/mt5'],
        children: [
            {
                title: 'Meta Trader 5',
                url: '/mt5'
            }
        ]
    },
    {
        title: 'Partnership',
        url: '/partnership',
        activeUrl: ['/partnership'],
    },
    {
        title: 'About Us',
        url: '/aboutUs',
        activeUrl: ['/aboutUs'],
    },
    {
        title: 'Contact Us',
        url: '/contactUs',
        activeUrl: ['/contactUs'],
    },
    {
        title: 'Blog',
        url: '/blogs',
        activeUrl: ['/blogs'],
    }
]