import React, { useEffect, useState } from 'react';
import './Metal.scss';
import Layout from '../../../Components/Layout/Layout.js';
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';

export default function Metal() {

    const navigate = useNavigate();

    const FeaturesData = [
        { icon: 'Assets/Images/how-it-works-2.svg', title: 'Wide Range', description: 'Options' },
        { icon: 'Assets/Images/wide-range.svg', title: '+154 Pair', description: 'of Coins' },
        { icon: 'Assets/Images/instruments.svg', title: 'Instruments', description: 'Features' },
        { icon: 'Assets/Images/how-it-works-4.svg', title: 'Indices', description: 'Forex & crypto markets' }
    ];

    const BenefitsData = [
        { icon: 'Assets/Images/competitive-spreads.svg', title: 'Trading Costs', description: 'Profit from spreads for Gold/US Dollar that are as low as zero and extremely low commission fees.' },
        { icon: 'Assets/Images/flexibility.svg', title: 'Flexibility', description: 'Get a range of trading options for metals, including spot trading, futures contracts, and options.' },
        { icon: 'Assets/Images/diversification.svg', title: 'Diversification', description: 'Trading metals provides a valuable diversification option for investors seeking to balance their portfolios.' },
        { icon: 'Assets/Images/high-liquidity.svg', title: 'High Liquidity', description: 'The metals market is a liquid market with high trading volumes and narrow bid-ask spreads.' },
    ];

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const statusIdentifier = (current, previous, digit) => {
        if (current?.toFixed(digit || 3) > previous?.toFixed(digit || 3)) {
            return '#28a745a1';
        } else {
            return '#ff00188c';
        }
    }

    return (
        <Layout>
            <Helmet>
                <title>Metals</title>
            </Helmet>
            <div className='instruments-metal-container'>
                <div className='metal-container py-5'>
                    <div className='side-padding py-5'>
                        <div className='py-4 head'>
                            <div>
                                <div className='title-white mb-4'>
                                    <p className='m-0'>Metal</p>
                                </div>
                                <p className='main-title'>Invest in the glittering world of precious metals trading with us</p>
                            </div>
                            <div className='text-right w-30 mt-5 mt-md-0'>
                                <p className='overview-head-text mb-4'>Trade Gold and Silver with confidence and flexibility on Pioneer Capital's robust trading platform.</p>
                                <div className='mt-5 pt-md-5 d-flex justify-content-end'>
                                    <button className='btn-white-bg mt-md-5' onClick={() => navigate('/login')}>Open Live Account</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='overview-container py-5'>
                    <div className='side-padding py-5'>
                        <div>
                            <div className='title-blue mb-4'>
                                <p className='m-0'>Overview</p>
                            </div>
                            <p className='main-title'>Join the metal trading revolution, and <span>diversify</span> your <span>investment</span> portfolio</p>
                            <p className='description mb-0 mt-4'>Learn about the advantages of trading metals, including Gold, and Silver, and how to get started in this exciting market.</p>
                            <button className='mt-5 btn-blue-bg' onClick={() => navigate('/login')}>Start Trading Now</button>
                        </div>
                    </div>
                </div>
                <div className='start-now'>
                    <div className='feature-container-bg-color'>
                        <div className='side-padding py-4'>
                            <div className='feature-container'>
                                {
                                    FeaturesData?.map((feature, index) => (
                                        <div className='feature-card my-3' key={index}>
                                            <div className='d-flex'>
                                                <div>
                                                    <div className='icon-wrapper'>
                                                        <img height={28} className='cursor-pointer' src={feature?.icon} alt='icon' />
                                                    </div>
                                                </div>
                                                <p className='title m-0 ml-3'>{feature?.title}</p>
                                            </div>
                                            <p className='m-0 mt-1 description'>{feature?.description}</p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>

                    <div className='benefits-container py-5'>
                        <div className='side-padding py-md-5'>
                            <div className='head'>
                                <div className='text-left'>
                                    <p className='head-text w-30 mb-4'>Leverage the power of trading metals during the times of volatility.</p>
                                    <div className='mt-5 d-flex'>
                                        <button className='btn-blue-bg' onClick={() => navigate('/login')}>Open Live Account</button>
                                    </div>
                                </div>
                                <div className='mt-5 mt-md-0'>
                                    <div className='title-blue mb-4'>
                                        <p className='m-0'>Benefits</p>
                                    </div>
                                    <p className='main-title'>Turn your investment goals into reality</p>
                                </div>
                            </div>
                            <div className='row m-0 px-0 pt-5'>
                                {
                                    BenefitsData?.map((benefit, index) => (
                                        <div key={index} className={`col-md-6 my-4 ${index % 2 == 0 ? 'pl-md-0 pr-md-4' : 'pr-md-0 pl-md-4'}`}>
                                            <div className='benefit-card py-4 pr-4'>
                                                <div className='icon-wrapper'>
                                                    <img height={32} className='cursor-pointer' src={benefit?.icon} alt='icon' />
                                                </div>
                                                <div className='pl-4'>
                                                    <p className='title my-3'>{benefit?.title}</p>
                                                    <p className='description m-0'>{benefit?.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
