import React, { useEffect } from 'react';
import './AboutUs.scss';
import Layout from '../../Components/Layout/Layout.js';
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";

export default function AboutUs() {

    const navigate = useNavigate();

    const OfferData = [
        { title: 'Our Vision', subTitle: 'The Pinnacle of Trading Experience Awaits You', description: "We aspire to be among the globe's top-tier online trading service providers, fostering a cohesive environment anchored in unwavering ethical standards. Our quest continues in evolving our solutions, synchronizing with market shifts, and embracing groundbreaking innovations.", img: 'Assets/Images/our-vision.svg' },
        { title: 'Our Mission', subTitle: 'Excellence in Service for a Dynamic Market', description: "Pioneer Capital, your esteemed forex broker, pledges to cultivate a fortified and refined trading ambiance. Our online forex tools harmoniously combine the intricacies suited for experts and the user-friendliness welcoming novices.", img: 'Assets/Images/our-mission.svg' },
        { title: 'Our Core Values', subTitle: 'Rooted in Transparency, Innovation, and Professionalism ', description: "At Pioneer Capital, we prioritize granting traders access to precise and trustworthy data, empowering them to make educated trading choices. Our commitment lies in ceaselessly refining our trading offerings, technology, and client engagement, ensuring our clients remain at the forefront, all while upholding paramount professional standards in our operations.", img: 'Assets/Images/our-values.svg' }
    ];

    const BenefitsData = [
        { icon: 'Assets/Images/favourable-swaps.svg', title: 'High Leverage and Competitive Spreads ', description: 'Seize abundant prospects to amplify your returns with significant leverage and enticing spreads. Partner with Pioneer Capital and capitalize on promising opportunities today! ' },
        { icon: 'Assets/Images/swift-deposit-withdrawal.svg', title: 'Swift Financial Transactions', description: 'In this rapid era, ensure you never skip a beat in your trading journey. Engage with Pioneer Capital for instantaneous deposits and the flexibility to withdraw your gains at your convenience.' },
        { icon: 'Assets/Images/prompt-technical-assistance.svg', title: 'Efficient Technical Support', description: 'Experience seamless trading with Pioneer Capital. Our seasoned technical brigade ensures prompt resolutions, making trading hitches a thing of the past.' },
    ];

    const PaymentMethodsData = [
        { icon: 'Assets/Images/ethereum-icon.svg', whiteBg: false, name: 'ETH', deposit: '$30 USD', depositSpeed: 'Instant', withdrawal: '$30 USD', withdrawalSpeed: 'Network Confirmation' },
        { icon: 'Assets/Images/bep-20-coin.svg', whiteBg: false, name: 'BEP-20', deposit: '$80 USD', depositSpeed: 'Instant', withdrawal: '$80 USD', withdrawalSpeed: 'Network Confirmation' },
        { icon: 'Assets/Images/tron-icon.svg', whiteBg: true, name: 'TRC-20', deposit: '$50 USD', depositSpeed: 'Instant', withdrawal: '$50 USD', withdrawalSpeed: 'Network Confirmation' },
        { icon: 'Assets/Images/white-bit-coin.svg', whiteBg: false, name: 'BTC', deposit: '$100 USD', depositSpeed: 'Instant', withdrawal: '$100 USD', withdrawalSpeed: 'Network Confirmation' },
    ];

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <Layout>
            <Helmet>
                <title>About us</title>
            </Helmet>
            <div className='about-us-container'>
                <div className='about-us-main'>
                    <div className='about-us-main-bg py-md-5'>
                        <div className='side-padding py-0'>
                            <div className='py-5'>
                                <div className='row p-0 m-0'>
                                    <div className='text-left col-md-7'>
                                        <div className='title-white mb-5'>
                                            <p className='m-0'>About us</p>
                                        </div>
                                        <p className='main-title'>Pioneer Capital - A Trusted Forex Broker</p>
                                        <p className='description mb-0 mt-5'>We offer exceptional forex trading solutions that elevate your trading game. Stay ahead with our cutting-edge technologies.</p>
                                        <button className='btn-white-bg mt-5' onClick={() => navigate('/login')}>Open Live Account</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='at-pioneer pt-5'>
                    <div className='side-padding py-md-5'>
                        <div className='text-left'>
                            <div className='title-blue mb-4 mt-5'>
                                <p className='m-0'>At Pioneer Capital</p>
                            </div>
                            <p className='main-title mb-4'>Discover Your Greatest <span> Potential </span> at Pioneer Capital</p>
                            <p className='m-0 description'>Grasp the Dynamics of Our Trading Platform. The forex market stands as one of the most vibrant trading arenas worldwide.</p>
                        </div>
                    </div>
                    <div className='payments-deposit-withdrawal-bg'></div>
                </div>

                <div className='what-we-offer py-5'>
                    <div className='side-padding py-md-5'>
                        <div className='row p-0 m-0'>
                            <div className='col-md-6 pl-md-0 pr-md-5'>
                                <div className='title-blue mb-4'>
                                    <p className='m-0'>What we bring to the table </p>
                                </div>
                                <p className='main-title mb-4'>Relish The Ultimate Online Forex Trading Journey</p>
                            </div>
                            <div className='col-md-6 pr-md-0 pl-md-5'>
                                <p className='m-0 description'>Elevate your forex pursuits with Pioneer Capital, your key to staying ahead in market trends. Access advanced trading tools such as the Economic Calendar, Calculator, Account Comparison, and much more. Dive into a variety of over 400 trading instruments with Pioneer Capital, your premier commodities broker. Experience trading that transcends conventional forex platforms.</p>
                            </div>
                        </div>

                        <div className='offer-card-container'>
                            {
                                OfferData?.map((offer, index) => (
                                    <div key={index} className='row m-0 p-0 offer-card my-5'>
                                        <div className='col-md-7 pt-4 pl-4 pr-md-5'>
                                            <p className='m-0 title'>{offer?.title}</p>
                                            <p className='my-3 sub-title'>{offer?.subTitle}</p>
                                            <p className='my-3 description'>{offer?.description}</p>
                                        </div>
                                        <div className='col-md-5 px-0 pr-md-0 text-right'>
                                            <img className='offer-img' src={offer?.img} alt='img' />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className='benefits-container py-5'>
                    <div className='side-padding py-md-5'>
                        <div className='head'>
                            <div className='text-left mr-md-5 pr-md-5'>
                                <p className='head-text mb-4'>Trade under favourable market conditions like never before!</p>
                                <div className='mt-5 d-flex'>
                                    <button className='btn-blue-bg' onClick={() => navigate('/login')}>Start Trading Now</button>
                                </div>
                            </div>
                            <div className='mt-5 mt-md-0'>
                                <div className='title-blue mb-4'>
                                    <p className='m-0'>Benefits</p>
                                </div>
                                <p className='main-title'>Embark on Trading in Optimal Market Settings Like Never Before.</p>
                            </div>
                        </div>
                        <div className='row m-0 px-0 pt-5'>
                            {
                                BenefitsData?.map((benefit, index) => (
                                    <div key={index} className={`col-md-4 my-4 ${index == 0 ? 'pl-md-0' : null} ${index == 2 ? 'pr-md-0' : null}`}>
                                        <div className='benefit-card py-4 pr-4'>
                                            <div className='icon-wrapper'>
                                                <img height={32} className='cursor-pointer' src={benefit?.icon} alt='icon' />
                                            </div>
                                            <div className='pl-4'>
                                                <p className='title my-3'>{benefit?.title}</p>
                                                <p className='description m-0'>{benefit?.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className='payment-methods py-5'>
                    <div className='side-padding py-md-5'>
                        <div className='head'>
                            <div>
                                <div className='title-blue mb-4'>
                                    <p className='m-0'>Payment Methods</p>
                                </div>
                                <p className='main-title'>Our Payment Methods</p>
                            </div>
                            <div className='text-right w-30 mt-5 mt-md-0'>
                                <p className='head-text mb-4'>Get started with our <span>super fast trading</span> account.</p>
                                <div className='mt-5 d-flex justify-content-end'>
                                    <button className='btn-blue-bg' onClick={() => navigate('/login')}>Get Started</button>
                                    <button className='btn-blue-border ml-3' onClick={() => navigate('/login')}>Open Demo Account</button>
                                </div>
                            </div>
                        </div>
                        <div className='row px-0 m-0 py-5'>
                            {
                                PaymentMethodsData?.map((method, index) => (
                                    <div key={index} className={`col-md-6 my-4 ${index % 2 == 0 ? 'pl-md-0 pr-md-4' : 'pr-md-0 pl-md-4'}`}>
                                        <div className='pyment-method-card p-4 p-md-5'>
                                            <div className='d-flex align-items-center'>
                                                <img src={method?.icon} alt='icon' className={`payment-method-coin-image ${method?.whiteBg ? 'payment-method-coin-image-bg-white' : ''}`} />
                                                <p className='payment-title m-0 ml-2'>{method?.name}</p>
                                            </div>
                                            <div className='inner-card mt-5 p-3 p-md-4'>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <div className='d-flex align-items-center'>
                                                        <img src='Assets/Images/wallet-blue-bg.svg' alt='icon' />
                                                        <p className='m-0 title ml-2'>Deposit <span>(Min)</span></p>
                                                    </div>
                                                    <p className='m-0 value'>{method?.deposit}</p>
                                                </div>
                                                <div className='d-flex justify-content-between align-items-center mt-4'>
                                                    <div className='d-flex align-items-center'>
                                                        <img src='Assets/Images/rocket.svg' alt='icon' />
                                                        <p className='m-0 title ml-2'>Speed</p>
                                                    </div>
                                                    <p className='m-0 value'>{method?.depositSpeed}</p>
                                                </div>
                                                <div className='devider my-3 w-75 mx-auto'></div>
                                                <div className='d-flex justify-content-between align-items-center mt-4'>
                                                    <div className='d-flex align-items-center'>
                                                        <img src='Assets/Images/bank-green-bg.svg' alt='icon' />
                                                        <p className='m-0 title ml-2'>Withdrawal <span>(Min)</span></p>
                                                    </div>
                                                    <p className='m-0 value'>{method?.withdrawal}</p>
                                                </div>
                                                <div className='d-flex justify-content-between align-items-center mt-4'>
                                                    <div className='d-flex align-items-center'>
                                                        <img src='Assets/Images/rocket.svg' alt='icon' />
                                                        <p className='m-0 title ml-2'>Speed</p>
                                                    </div>
                                                    <p className='m-0 value'>{method?.withdrawalSpeed}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
