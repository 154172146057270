import React from 'react';
import './ContactContainer.scss';
import ContactForm from '../../../Components/Common/ContactForm';

export default function ContactContainer() {
    return (
        <div className='contact-form-container'>
            <div className='side-padding'>
                <div className='row my-5 py-5'>
                    <div className='col-md-6'>
                        <div>
                            <div className='title-blue'>
                                <p className='m-0'>We’re 24/7 Online</p>
                            </div>
                            <div className='mt-3'>
                                <p className='contact-title mb-4'>Get in touch with our team</p>
                                <p className='description'>Connect with us today and let our team assist you!</p>
                            </div>
                        </div>
                        <div className='help-container mt-5 pt-md-4'>
                            <p className='title mb-4'>Our Team Can help you with</p>
                            <div className='d-flex align-items-center mb-2'><img className='mr-3' src='Assets/Images/true-badge.svg' alt='icon' /><p className='m-0 help-text'>Account Setup</p></div>
                            <div className='d-flex align-items-center mb-2'><img className='mr-3' src='Assets/Images/true-badge.svg' alt='icon' /><p className='m-0 help-text'>Issues with trading</p></div>
                            <div className='d-flex align-items-center mb-2'><img className='mr-3' src='Assets/Images/true-badge.svg' alt='icon' /><p className='m-0 help-text'>Account related queries</p></div>
                            <div className='d-flex align-items-center mb-2'><img className='mr-3' src='Assets/Images/true-badge.svg' alt='icon' /><p className='m-0 help-text'>Any Spam Activities</p></div>
                        </div>
                        <div className='pt-5 location-info'>
                            <div className='mt-3'>
                            <p className='mb-2 title mt-3'>Physical Address:</p>
                                <p className='address mb-2'>811, Ascon House, Al Muteena, Dubai, United Arab Emirates.</p>
                                <p className='address mb-3'>Business Hours: Mon-Fri: 09:00 AM - 06:00 PM</p>
                                <div className='d-flex align-items-center mb-2'>
                                    <img src='Assets/Images/phone-blue.svg' height={20} alt='icon' />
                                    <a className='call-link' href="tel:+971585802446" target='_blank'>
                                        <p className='m-0 pl-2 address'>+971 58 580 2446</p>
                                    </a>
                                </div>
                                <div className='mt-5'>
                                    <p className='mb-2 title mt-3'>Registered Address:</p>
                                    <p className='address mb-2'>Ground Floor, The Sotheby Building, Rodney Village, Rodney Bay, Gros-Islet, Saint Lucia.</p>
                                    <p className='address'>(Registration No. 2024-00474)</p>
                                </div>
                            </div>

                            {/* ---------- Temporary removed ---------- */}
                            {/* <div className='mt-5'>
                                <p className='title m-0'>Follow us on</p>
                                <div className='mt-3 text-left'>
                                    <a>
                                        <img height={24} width={24} className='mr-4 cursor-pointer' src='Assets/Images/facebook-blue.svg' alt='icon' />
                                    </a>
                                    <a>
                                        <img height={20} width={20} className='mx-4 cursor-pointer' src='Assets/Images/twitter-blue.svg' alt='icon' />
                                    </a>
                                    <a>
                                        <img height={24} width={24} className='mx-4 cursor-pointer' src='Assets/Images/linkedin-blue.svg' alt='icon' />
                                    </a>
                                    <a>
                                        <img height={24} width={24} className='ml-4 cursor-pointer' src='Assets/Images/instagram-blue.svg' alt='icon' />
                                    </a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className='col-md-6 mt-5 mt-md-0'>
                        <ContactForm badge='Available 24/7' buttonText='Submit now' privacyPolicy={true} />
                    </div>
                </div>
            </div>
        </div>
    )
}